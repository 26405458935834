export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    // let url = `/api/application-form/grid?`;
    // let url = `/api/application-form/grid?steps.1.status=in[1,2,3,4]&`;
    let url = `/api/application-form/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/application-form/detail?id=${id}`,
  save: `/api/application-form/create`,
  delete: (id) => `/api/application-form/delete?id=${id}`,
  bulkDelete: (id) => `/api/application-form/delete`,
  update: `/api/application-form/update`,
};



export const STRUCTURE = [
  {
    label: "Name",
    filterLabel: "Customer Name",
    filterName: "customerName",
    type: "text",
    // description: "Filter by customer name",
    icon: " uil-user-circle font-size-16 ",
  },
  {
    label: "Application No",
    filterLabel: "Application No",
    filterName: "applicationNo",
    // description: "Filter by email address",
    type: "text",
    icon: "mdi mdi-format-list-numbered font-size-16 ",
  },
  {
    label: "Email",
    filterLabel: "Email",
    filterName: "email",
    // description: "Filter by email address",
    type: "text",
    icon: "mdi mdi-email-outline font-size-16 ",
  },

  {
    label: "Introducer Name",
    filterLabel: "Introducer Name",
    filterName: "introducer",
    // description: "Filter by email address",
    type: "UTMTrackerSelect",
  },

  {
    label: "Application Type",
    filterLabel: "Application Type",
    filterName: "formType",
    type: "select",
    // description: "Filter by the type of Application",
    data: [
      { label: "Business Plus", value: "BUSINESS_PLUS" },
      { label: "Individual Plus", value: "INDIVIDUAL_PLUS" },
      { label: "Business Prime", value: "BUSINESS_PRIME" },
      { label: "Individual Prime", value: "INIDIVIDUAL_PRIME" },
    ],
    icon: "mdi mdi-file-document-outline font-size-16 ",
  },
  // {
  //   label: "Status",
  //   filterLabel: "Status",
  //   filterName: "status",
  //   // description: "Filter by Status",
  //   type: "select",
  //   data: [
  //     { label: "Draft", value: 0 },
  //     { label: "Submitted", value: 1 },
  //     { label: "Ask For Info", value: 2 },
  //     { label: "Approved", value: 3 },
  //     { label: "Rejected", value: 4 },
  //   ],
  // },

];
