import _ from "lodash";
import { getBaseUrls } from "../../../../constants";

const baseUrls = getBaseUrls()

const getPoaVerificationData = (poaInfo) => {
    const documents = poaInfo?.verification_data?.proof_of_address_documents?.length
        ? poaInfo?.verification_data?.proof_of_address_documents
        : poaInfo?.verification_data?.invalid_documents || [];

    return documents.map(doc => {
        const street = doc?.address?.street_1 || "-";
        const street2 = doc?.address?.street_2;
        const fullStreet = street2 ? `${street}, ${street2}` : street;

        return {
            documentDate: doc?.date || "-",
            documentType: doc?.document_type || "-",
            nameOnDocument: doc?.name || "-",
            address: {
                street: fullStreet,
                city: doc?.address?.city,
                state: doc?.address?.state,
                postal_code: doc?.address?.postal_code,
            },
            summary: doc?.summary || "-",
            analysis: doc?.analysis || "-",
            isValidDocument: doc?.is_valid_document,
            alerts: _.map(doc?.alerts ?? {}, (value, key) => ({ key, value })),
        };
    });
};

export const getParchaFilteredData = (poaData) => {
    if (poaData?.check_results?.length) {
        const filteredData = poaData?.check_results?.filter(
            (message) =>
                (['CommandResult', "Error"]?.includes(message?.result_type)) &&
                (message?.command_id === 'kyc.proof_of_address_verification' || message?.command_id === 'kyb.proof_of_address_verification')
        ) ?? [];
        return (filteredData ?? [])?.map(poaInfo => (
            {
                _id: poaData?._id,
                thirdPartyId: poaData?.id,
                internalStatus: poaData?.status,
                redirectionLink: `${baseUrls?.parcha}/${poaData?.id}`,
                event: poaInfo?.result_type == "CommandResult" ? "success" : "error",
                statusDisplay: _.capitalize(
                    (poaInfo?.result_type == "Error" || poaData?.status == "error") ? "Error" :
                        (poaInfo?.command_id === 'kyc.proof_of_address_verification' || poaInfo?.command_id === 'kyb.proof_of_address_verification') && poaInfo?.status == "complete" ? "Completed" :
                            poaData?.status ?? "Pending"),
                toShowCompletedData:( (poaInfo?.command_id === 'kyc.proof_of_address_verification' || poaInfo?.command_id === 'kyb.proof_of_address_verification') && poaInfo?.status == "complete"),
                errorType: poaInfo?.error?.error_type ?? "-",
                errorDisplay: poaInfo?.error?.error_display ?? "-",
                fullErrorMessage: poaInfo?.error?.full_error_message ?? "-",
                isPassed: poaInfo?.passed,
                statusReason: poaInfo?.answer ?? "-",
                statusExplanation: poaInfo?.explanation ?? "-",
                input: {
                    fullName: poaInfo?.input_data?.full_name ?? "-",
                    address: {
                        street: poaInfo?.input_data?.address?.street_1 ?? "-",
                        city: poaInfo?.input_data?.address?.city,
                        state: poaInfo?.input_data?.address?.state,
                        postal_code: poaInfo?.input_data?.address?.postal_code,
                    }
                },
                poaVerificationData: getPoaVerificationData(poaInfo),
            }))[0];
    } else {
        const filteredData = poaData?.status_messages?.filter(
            (message) =>
                (message?.event === 'command_results' || message?.event === 'error') &&
                (message?.content?.command_id === 'kyc.proof_of_address_verification' || message?.content?.command_id === 'kyb.proof_of_address_verification')
        ) ?? [];
        return (filteredData ?? [])?.map(poaInfo => (
            {
                _id: poaData?._id,
                thirdPartyId: poaData?.id,
                internalStatus: poaData?.status,
                redirectionLink: `${baseUrls?.parcha}/${poaData?.id}`,
                event: poaInfo?.event == "command_results" ? "success" : "error",
                statusDisplay: _.capitalize(
                    (poaInfo?.event == "error" || poaData?.status == "error") ? "Error" :
                        (poaInfo?.content?.command_id === 'kyc.proof_of_address_verification' || poaInfo?.content?.command_id === 'kyb.proof_of_address_verification') ? "Completed" :
                            poaData?.status ?? "Pending"),
                toShowCompletedData: ((poaInfo?.content?.command_id === 'kyc.proof_of_address_verification' || poaInfo?.content?.command_id === 'kyb.proof_of_address_verification')),
                errorType: poaInfo?.content?.output?.error_type ?? "-",
                errorDisplay: poaInfo?.content?.output?.error_display ?? "-",
                isPassed: poaInfo?.content?.output?.passed,
                statusReason: poaInfo?.content?.output?.answer ?? "-",
                statusExplanation: poaInfo?.content?.output?.explanation ?? "-",
                instructions: poaInfo?.content?.output?.instructions ?? "-",
                input: {
                    fullName: poaInfo?.content?.args?.input_data?.full_name ?? "-",
                    address: {
                        street: poaInfo?.content?.args?.input_data?.address?.street_1 ?? "-",
                        city: poaInfo?.content?.args?.input_data?.address?.city,
                        state: poaInfo?.content?.args?.input_data?.address?.state,
                        postal_code: poaInfo?.content?.args?.input_data?.address?.postal_code,
                    }
                },
                poaVerificationData: poaInfo?.content?.args?.verification_data?.proof_of_address_documents?.map(v => ({
                    documentDate: v?.date ?? "-",
                    documentType: v?.document_type ?? "-",
                    nameOnDocument: v?.name ?? "-",
                    address: {
                        street: v?.address?.street_1 ?? "-",
                        city: v?.address?.city,
                        state: v?.address?.state,
                        postal_code: v?.address?.postal_code,
                    },
                    summary: v?.summary ?? "-",
                    analysis: v?.analysis ?? "-",
                    isValidDocument: v?.is_valid_document,
                }))
            }))[0];
    }
}