// AuditLogDialog.jsx

import React from "react";import AuditLogService from "./AuditLogService";
import { Button } from "reactstrap";

const AuditLogButton = ({description, title,  modules = null, module = null, id = null, asLabel }) => {

  const handleAuditButtonClick = (e) => {
    AuditLogService?.toggleModal({ description, modules, module, id, title })
  };

  return (
    <>
      <AuditLogToggleButton title={title} asLabel={asLabel} handleAuditButtonClick={(e) => { handleAuditButtonClick(e) }} />
    </>
  );
};

const AuditLogToggleButton = ({ t, handleAuditButtonClick, asLabel }) => {
  const containerClass = asLabel ? "d-flex" : "d-flex justify-content-end mx-2 my-0";
  const buttonStyle = { fontSize: "16px", fontWeight: 400, backgroundColor: '#1678AE', height: "40px", padding: "2px 4px 2px 4px", width: "120px", borderRadius: "10px" }
  return (
    <div onClick={handleAuditButtonClick} className={containerClass}>
      <Button
        color="success"
        style={buttonStyle}>
        <span className="align-self-center">{('Audit Log')}</span>
      </Button>
    </div>
  );
};

export default AuditLogButton;
