import moment from 'moment';
import React, { useState } from 'react';
import { FaRobot } from 'react-icons/fa';
import DocViewer from '../DocUpload/DocViewer';
import SupervisorSelect from '../Dropdowns/SupervisorSelect/SupervisorSelect';
import { getBackgroundColorForStatusViaString, getColorForStatusViaString } from '../dnd/board/helper';
import './Comment.scss';


const CommentItem = ({ comment, uboDetail }) => {
    const [readMore, setReadMore] = useState(false);
    const commentDate = moment(comment.at * 1000);
    const timeSinceComment = commentDate.fromNow();


    const commentClass =  comment?.by == "System"
        ? "comment-item-system-generated"
        : "comment-item-human";

    const toggleReadMore = () => {
        setReadMore(!readMore);
    };

    const renderContent = (content) => {
        if (typeof content !== 'string') {
            return '';
        }

        if (readMore) {
            return content.split('\n').map((line, index) => (
                <div key={index}>{line}</div>
            ));
        } else {
            const words = content.split(' ');
            if (words.length > 20) {
                return `${words.slice(0, 15).join(' ')}... `;
            }
            return content;
        }
    };

    const getUser = () => {
        return comment?.by ?? comment?.updatedBy ?? comment?.createdBy;
    };
    const user = getUser();
    const [_seq = '', _step = '', _status = ''] = (comment?.content?.title ?? "|||")?.split('|');

    return (
        <div>
            <div
                style={comment?.by == "System" ? {
                    borderLeft: `5px solid ${getColorForStatusViaString(_status)}44`,
                } : {}}
                className={`comment-item ${commentClass} d-flex`}>

                <div>
                    <div className='d-flex'>
                        {user === "System" ? (
                            <div className="system-comment">
                                <FaRobot size={20} /> {/* This is the icon for system-generated comments */}

                            </div>
                        ) : user ? (
                            <SupervisorSelect
                                value={user}
                                onChange={() => { }}
                                asLabel
                            />
                        ) : null}

                    </div>
                </div>

                <div className="comment-content font-size-14" style={{ fontWeight: 'bold' }}>
                    <div className={`px-3 pe-0  d-flex  align-items-start  justify-content-end w-100`}>

                        {comment?.content?.title && <div
                            style={{
                                borderRadius: "10px ",
                                fontSize: "12px",
                                lineHeight: 1,
                                border: `2px solid ${getColorForStatusViaString(_status ?? _step)}`,
                                color: `${getColorForStatusViaString(_status ?? _step)}`,
                                backgroundColor: `${getBackgroundColorForStatusViaString(_status ?? _step)}`
                            }}
                            className={` p-0 m-0 `}
                        >
                            <div className='d-flex align-items-center'>
                                <div
                                    style={{
                                        borderRight: `2px solid ${getColorForStatusViaString(_status ?? _step)}`,
                                        backgroundColor: "#ffffffdd",
                                        borderRadius: "8px 0px 0px 8px",

                                    }}
                                    className='py-1 px-2 me-2 '
                                >
                                    {_seq}
                                </div>
                                <em className='my-1 me-2'>
                                    {`${_status ? `${_status} - ` : ""}${_step}${comment?.ubo_id ? ` for  ${uboDetail?.[comment?.ubo_id]?.firstName} ${uboDetail?.[comment?.ubo_id]?.lastName}` : ''}`}
                                </em>
                            </div>
                        </div>}
                    </div>
                    <div className={`comment-remark px-3 pe-0 d-flex flex-column align-items-end justify-content-start w-100`}>
                        {renderContent(comment?.content?.remark)}
                        {comment?.content?.remark && comment?.content?.remark.split(' ')?.length > 20 && (
                            <span className="read-more" onClick={toggleReadMore}>
                                {readMore ? 'Read less' : 'Read more'}
                            </span>
                        )}

                        <DocViewer documents={comment?.content?.attachment?.urls} />

                    </div>

                    <div className="comment-time d-flex justify-content-end">{timeSinceComment}</div>
                </div>


            </div >

            {/* <Documents single module="comment" record_id={comment?._id} /> */}
        </div>

    );
};

export default CommentItem;
