import { ThreeBounce } from 'better-react-spinkit';
import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Button } from 'reactstrap';
import { Image, ModalComponent } from '../../../../components';
import DocTypeDropdown from '../../../../components/DocUpload/DocTypeDropDown';
import PDFLabel from '../../../../components/Image/PDFLabel';
import { DialogContext } from '../../../../store/context/DialogContext';
import { UserContext } from '../../../../store/context/UserContext';
import { doGET, doPOST, doPUT } from '../../../../util/HttpUtil';
import StatusDropDown from '../complyAdvantage/StatusDropDown';
import { InfoPair } from '../ondato/Ondato';
import ProofOfAddress from '../proofOfAddress/ProofOfAddress';
import DocumentGrid from './DocumentGrid';
import './style.scss';
import { FaCircle, FaCheckCircle, FaExclamationTriangle, FaTimesCircle, FaHourglassStart, FaHourglassEnd, FaBan, FaThumbsDown, FaArchive } from 'react-icons/fa';

export function getFilenameFromPath(path) {
  const parts = path?.split('/');
  return parts?.[parts?.length - 1];
}

const basicDocumentTypes = [
  { value: "Business_Proof_Address", label: "Business Proof Address", forBusiness: true },
  { value: "BusinessTaxIdProof", label: "Business Tax Id Proof", forBusiness: true },
  { value: "SourceOfBusFundsProof", label: "Source Of Funds for Business", forBusiness: true },
  { value: "ProofOfAddress", label: "Proof Of Address", forBusiness: false },
  { value: "SourceOfFunds", label: "Source Of Funds", forBusiness: false },
]

function getUniqueTypes(arr) {
  const typesSet = new Set();
  arr.forEach(obj => {
    if (obj.hasOwnProperty('type')) {
      typesSet.add(obj.type);
    }
  });

  return Array.from(typesSet);
}



const GroupDocumentCheckBox = ({ documentTypes, onDocumentSelect, applicationDocuments = [], ubo_id }) => {

  const uniqueApplicationDocTypes = getUniqueTypes(applicationDocuments);

  const filteredDocumentTypes = documentTypes.filter(field =>
    !uniqueApplicationDocTypes.includes(field.value)
  );

  return (
    <div className="mb-2">

      {!filteredDocumentTypes?.length ? <h4>You've already requested all the required document types from the applicant. </h4> : null}
      {(filteredDocumentTypes ?? []).map((field, index) => (
        <div key={index} className="">
          <label className='d-flex align-items-center'>
            <input
              type="checkbox"
              className='me-1'
              onChange={() => onDocumentSelect(ubo_id ? { ...field, ubo_id: ubo_id } : field)}
            />
            {field.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export const StatusDisplay = ({ value }) => {
  return <div className='d-flex align-items-center'>
    <FaCircle color={value?.backgroundColor} className='me-1' />  <span style={{ color: value?.backgroundColor }}>{value?.label}</span>
  </div>
}

export const StatusDisplayInvert = ({ hideIcons, style, value }) => {
  return (
    <div
      style={{ backgroundColor: value?.backgroundColor, padding: "2px 6px", ...(style ?? {}) }}
      className='d-flex align-items-center justify-content-center rounded-2'
    >
      {!hideIcons ? <div className='pb-1' style={{ color: value?.keyColor, fontSize: 15, marginRight: 4 }}>
        {value?.icon}
      </div> : null}
      <div className='pb-0' style={{ color: value?.keyColor, fontSize: 12 }}>
        {value?.label}
      </div>
    </div>
  );
};

const ApplicationDocument = ({ isLocked, application_id, reload, refreshId, formType }) => {
  const [applicationDocuments, setApplicationDocuments] = useState(null);
  const [uboIdName, setUboIdName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [askDocumentsModal, setAskDocumentsModal] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [documentTypeMap, setDocumentTypeMap] = useState({});
  const detailRefs = useRef({});
  const { showError } = useContext(DialogContext)
  const { userContent } = useContext(UserContext);

  const handleScrollToDetail = (docType, index, docFor) => {
    const refKey = `${docFor ? docFor + "-" : ""}${docType}-${index}`;

    // Iterate over all references and remove the highlighted class
    Object.keys(detailRefs.current).forEach(key => {
      if (detailRefs.current[key]) {
        detailRefs.current[key].classList.remove('highlighted-border');
      }
    });

    // Add the highlighted class to the current key
    if (detailRefs.current[refKey]) {
      detailRefs.current[refKey].classList.add('highlighted-border');
      detailRefs.current[refKey].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };


  const fetchUBOs = async () => {
    if (application_id) {
      setLoading(true);
      try {
        const uboResponse = await doGET(`/api/ubo/grid?application_id=${application_id}`);
        if (uboResponse.status === 200) {
          const obj = {};
          (uboResponse?.data?.rows ?? []).forEach((v) => {
            obj[v?._id] = v;
          });
          setUboIdName(obj);
        } else {
          throw new Error('Failed to fetch documents');
        }
      } catch (error) {
        console.error('Error fetching ubo details:', error);

      } finally {
        setLoading(false);
      }
    }
  }

  const fetchDocuments = async () => {
    if (application_id) {
      setLoading(true);
      try {
        const response = await doGET(`/api/application-doc/grid?form_id=${application_id}&rows=-1`);
        if (response.status === 200) {
          setApplicationDocuments(response?.data?.rows);
        } else {
          throw new Error('Failed to fetch documents');
        }
      } catch (error) {
        console.error('Error fetching documents:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchRequirements = async () => {
    await fetchUBOs();
    await fetchDocuments();
  }

  useEffect(() => {
    fetchRequirements()
  }, [application_id, refreshId]);

  const fetchDocumentTypes = async () => {
    try {
      const docTypeResponse = await doGET(`/api/doc-type/grid?rows=-1`);
      if (docTypeResponse?.status == 200) {
        setDocumentTypes(docTypeResponse?.data?.rows?.map((v) => {
          return { ...v, value: v?.key ?? v?._id, label: v?.name }
        }))
        setDocumentTypeMap(docTypeResponse?.data?.rows.reduce((acc, obj) => {
          if (obj?.key && !acc[obj?.key]) {
            acc[obj?.key] = obj?.name;
          } else if (obj?._id && !acc[obj?._id]) {
            acc[obj?._id] = obj?.name;
          }
          return acc;
        }, {}))
      }
    } catch (error) {
      showError(error)
    }
  }

  useEffect(() => {
    fetchDocumentTypes();
  }, [])

  const groupDocumentsByUbo = (documents) => {
    const grouped = { applicationDocuments: [] };

    documents.forEach(doc => {
      if (doc.ubo_id) {
        if (!grouped[doc.ubo_id]) {
          grouped[doc.ubo_id] = [];
        }
        grouped[doc.ubo_id].push(doc);
      } else {
        grouped.applicationDocuments.push(doc);
      }
    });

    return grouped;
  };

  if (loading) {
    return <ApplicationDocumentSkeleton />;
  }

  if (!applicationDocuments) {
    return (
      <div className="fv_risk_assessment_error">
        <h4><b>No Documents found</b></h4>
      </div>
    );
  }

  const groupedDocuments = groupDocumentsByUbo(applicationDocuments);

  const handleDocumentSelect = (field) => {
    setSelectedDocuments(prev => {
      const isAlreadySelected = prev.some(doc => doc.value === field.value && doc.ubo_id === field.ubo_id);
      if (isAlreadySelected) {
        return prev.filter(doc => !(doc.value === field.value && doc.ubo_id === field.ubo_id));
      } else {
        return [...prev, field];
      }
    });
  };

  const handleAskSubmit = async () => {
    const payload = selectedDocuments.map(doc => {
      if (doc?.forBusiness) {
        return { type: doc.value, form_id: application_id };
      } else {
        return { type: doc.value, ubo_id: doc.ubo_id, form_id: application_id };
      }
    });

    setLoading(true)
    try {
      const response = await doPOST("/api/application-doc/create/empty", payload)
      if (response?.status == 200) {

        await fetchDocuments();
        setAskDocumentsModal(false)
        setSelectedDocuments([])
        reload();

      }
    } catch (error) {
      showError(error)
    } finally {
      setLoading(false)
    }
  };

  return (
    <>

      {(applicationDocuments?.length > 1) ? <DocumentGrid
        matchStatusOptions={matchStatusOptions}
        groupedDocuments={groupedDocuments}
        applicationDocuments={groupedDocuments?.applicationDocuments}
        uboIdName={uboIdName}
        documentTypeMap={documentTypeMap}
        onRowClick={handleScrollToDetail}
      /> : null}

      {(userContent?.rights?.includes(2802) && !isLocked) ?
        <div className="d-flex justify-content-end my-2 me-2">
          <Button
            onClick={() => { setAskDocumentsModal(true) }}
            className=""
            style={{ borderColor: "#1678AE", fontSize: "16px", height: "40px", padding: "2px 4px 2px 4px", width: "230px", borderRadius: "10px" }}
            color="outline-primary"
          >
            {loading ? <ThreeBounce color="#1678AE" size={8} /> : <>{("Ask Additional Documents")} </>}
          </Button>
        </div>
        : null}

      {applicationDocuments?.length ? (
        <div className="fv_application_document_card">

          {groupedDocuments.applicationDocuments?.length && (formType == "BUSINESS_LITE" || formType == "BUSINESS_PLUS") ? (
            <>
              <h4>Business Documents</h4>
              <GroupDocumentCard
                isLocked={isLocked}
                detailRefs={detailRefs}
                reload={reload}
                documentTypes={(documentTypes?.length ? documentTypes : basicDocumentTypes)?.filter(v => v?.forBusiness)}
                documentTypeMap={documentTypeMap}
                fetchDocuments={fetchDocuments}
                documents={groupedDocuments.applicationDocuments ?? []}
                // canChangeType={formType == "BUSINESS_LITE" || formType == "BUSINESS_PLUS"}
                canChangeType={true}
              />
            </>
          ) : null}

          {Object.keys(groupedDocuments)
            .filter((key) => key !== "applicationDocuments")
            .map((ubo_id, index) => (
              <div key={index} className="fv_application_document-section">
                <h4>
                  Documents for {uboIdName?.[ubo_id]?.firstName ?? ""}{" "}
                  {uboIdName?.[ubo_id]?.lastName ?? ""}
                </h4>
                <GroupDocumentCard
                  title={`${uboIdName?.[ubo_id]?.firstName ?? ""} ${uboIdName?.[ubo_id]?.lastName ?? ""
                    }`}
                  detailRefs={detailRefs}
                  isLocked={isLocked}
                  reload={reload}
                  documentTypes={(documentTypes?.length ? documentTypes : basicDocumentTypes)?.filter(v => !v?.forBusiness)}
                  documentTypeMap={documentTypeMap}
                  fetchDocuments={fetchDocuments}
                  documents={groupedDocuments[ubo_id] ?? []}
                  // canChangeType={formType == "BUSINESS_LITE" || formType == "BUSINESS_PLUS"}
                  canChangeType
                />
              </div>
            ))}
        </div>
      ) : null}


      {
        askDocumentsModal ?
          <ModalComponent
            size="medium"
            position={"centered"}
            isOpen={askDocumentsModal}
            onToggle={() => {
              setSelectedDocuments([])
              setAskDocumentsModal(false)
            }}>
            <ModalComponent.Title>Ask Additional Documents</ModalComponent.Title>
            <ModalComponent.Body>
              {(formType == "BUSINESS_LITE" || formType == "BUSINESS_PLUS") ? <div className='mb-3' >
                <h4 className='py-1 px-2 w-100 bg-light text-dark'> <b> Business Related Documents </b></h4>
                <GroupDocumentCheckBox
                  documentTypes={(documentTypes?.length ? documentTypes : basicDocumentTypes)?.filter(v => v?.forBusiness)}
                  documentTypeMap={documentTypeMap}
                  applicationDocuments={groupedDocuments.applicationDocuments}
                  onDocumentSelect={handleDocumentSelect}
                />
              </div> : null}

              {Object.keys(groupedDocuments)
                .filter(key => key !== "applicationDocuments")
                .map((ubo_id, index) => (
                  <div className='' key={index}>
                    <h4 className='py-1 px-2 w-100 bg-light text-dark'> Documents for <b> {uboIdName?.[ubo_id]?.firstName ?? ""} {uboIdName?.[ubo_id]?.lastName ?? ""} </b></h4>
                    <GroupDocumentCheckBox
                      documentTypes={(documentTypes?.length ? documentTypes : basicDocumentTypes)?.filter(v => !v?.forBusiness)}
                      documentTypeMap={documentTypeMap}
                      ubo_id={ubo_id}
                      applicationDocuments={groupedDocuments?.[ubo_id]}
                      onDocumentSelect={handleDocumentSelect}
                    />
                  </div>
                ))}
            </ModalComponent.Body>
            <ModalComponent.Footer>
              <Button disabled={!selectedDocuments?.length} onClick={handleAskSubmit}>Ask</Button>
            </ModalComponent.Footer>
          </ModalComponent>
          : null
      }
    </>
  );

};

export const matchStatusOptions = [
  { label: "Draft", value: 0, backgroundColor: "#CCCCCC" },
  { label: "Submitted", value: 1, backgroundColor: "#A5A5A5" },
  { label: "Ask For Info", value: 2, backgroundColor: "#F0C419" },
  { label: "Approved", value: 3, backgroundColor: "#9BC39B" },
  { label: "Rejected", value: 4, backgroundColor: "#E57C7A" },
  { label: "Locked", value: 5, backgroundColor: "orange" },
];

export const applicationStatuses = [
  { label: "Draft", value: 0, backgroundColor: "#E0E0E0", keyColor: "#4A4A4A", icon: <FaCircle /> }, // Soft Grey with Neutral Circle Icon
  { label: "Submitted", value: 1, backgroundColor: "#C1C1C1", keyColor: "#333333", icon: <FaHourglassStart /> }, // Softer Grey with Hourglass Start Icon
  { label: "Incomplete", value: 2, backgroundColor: "#F8D76E", keyColor: "#4A4A4A", icon: <FaExclamationTriangle /> }, // Soft Yellow with Exclamation Triangle Icon
  { label: "Approved", value: 3, backgroundColor: "#B2D8B2", keyColor: "#3D5941", icon: <FaCheckCircle /> }, // Soft Green with Check Circle Icon
  { label: "Rejected", value: 4, backgroundColor: "#F1A7A6", keyColor: "#8B4A4A", icon: <FaTimesCircle /> }, // Soft Red with Times Circle Icon
  { label: "Account Requested", value: 5, backgroundColor: "#D3CCE3", keyColor: "#4A3D59", icon: <FaHourglassEnd /> }, // Soft Lavender with Hourglass End Icon
  { label: "Account Created", value: 6, backgroundColor: "#B5EAD7", keyColor: "#3D5D4A", icon: <FaCheckCircle /> }, // Soft Mint Green with Check Circle Icon
  { label: "Account Creation Failed", value: 7, backgroundColor: "#F2B87E", keyColor: "#7A4A2D", icon: <FaBan /> }, // Soft Orange with Ban Icon
  { label: "Declined", value: 8, backgroundColor: "#F1A7A6", keyColor: "#8B4A4A", icon: <FaThumbsDown /> },
  { label: "Archived", value: 9, backgroundColor: "#B0BEC5", keyColor: "#37474F", icon: <FaArchive /> },
];


const statusMap = {
  0: "Draft",
  1: "Submitted",
  2: "Ask For Info",
  3: "Approved",
  4: "Rejected",
}


const GroupDocumentCard = ({ isLocked, documentTypes, documentTypeMap, canChangeType, title, documents = [], reload, fetchDocuments, detailRefs }) => {
  const groupedByType = _.groupBy(documents, 'type');

  return (
    <div className={"fv_application_document-group"}>
      {Object.keys(groupedByType).map((type, typeIndex) => (
        <div key={typeIndex} className={groupedByType[type]?.length ? "fv_application_document-type-group" : ""}>
          {/* <h5>{type === "SourceOfBusFundsProof" ? "Source Of Funds for Business" : _.startCase(_.camelCase(type))}</h5> */}
          {groupedByType[type]
            .sort((a, b) => b.seq - a.seq)
            .map((doc, index) => (

              <DocumentCard
                title={title ?? "Business Documents"}
                reload={reload}
                canChangeType={canChangeType}
                onStatusChange={fetchDocuments}
                docIndex={index}
                document={doc}
                type={type}
                documentTypes={documentTypes}
                documentTypeMap={documentTypeMap}
                detailRefs={detailRefs}
                locked={index !== 0 || isLocked} // only the first document (latest) will have locked={false}
              />

            ))}
        </div>
      ))}
    </div>
  );
};



const DocumentCard = ({ documentTypes, documentTypeMap, canChangeType, title, reload, document, onStatusChange, docIndex, locked, type, detailRefs }) => {

  const { showConfirm, showConfirmWithTextAreaAndCheckBox, showMessage, showError, showConfirmWithTextArea } = useContext(DialogContext)
  const [loading, setLoading] = useState(false);
  const { userContent, } = useContext(UserContext);
  const handleStatusChange = async (v) => {
    setLoading(true)

    const result = v == 4 ?
      await showConfirmWithTextAreaAndCheckBox({
        title: (`Do you want to change status of ${documentTypeMap?.[document?.type] ?? document?.type} for ${title} to ${statusMap[v ?? 0]} ?`),
        description: (`${v == 4 ? "Upon rejecting this, it will reject entire application." : ""}`),
        remarkRequired: true,
        checkboxRequired: true,
        checkboxLabel: "Notify Applicant",
        defaulValueCheckBox: true
      })
      : v == 2 ? await showConfirmWithTextArea({
        title: (`Do you want to change status of ${documentTypeMap?.[document?.type] ?? document?.type} for ${title} to ${statusMap[v ?? 0]} ?`),
        remarkRequired: true,
        remarkPlaceholder: "Remark for Applicant"
      }) : await showConfirm({
        title: (`Do you want to change status of ${documentTypeMap?.[document?.type] ?? document?.type} for ${title} to ${statusMap[v ?? 0]} ?`),
        description: (`${v == 4 ? "Upon rejecting this, it will reject entire application." : ""}`)
      })

    if (result === true || (result && result.confirmed)) {
      try {
        const response = await doGET(`/api/application-doc/detail?id=${document?._id}`);
        const updatedStatus = await doPUT(`/api/application-doc/update${result?.isChecked ? "?notifyApplicant=true" : ""}`, {
          ...(response?.data),
          flowStatus: v,
          ...((v == 2 || v == 4) && { remark: result?.remark ?? "" })
        });
        showMessage("Status Changed SuccessFully", "Success");
        onStatusChange();
        reload()
      } catch (e) {
        showError(e);
      } finally {
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }

  const handleTypeChange = async (field, v) => {
    try {
      const response = await doGET(`/api/application-doc/detail?id=${document?._id}`);
      await doPUT(`/api/application-doc/update`, {
        ...(response?.data),
        docCategory_id: null,
        [field]: v,
      });
      showMessage("Document Updated", "Success");
      onStatusChange();
      reload();
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false)
    }
  }

  const hasUnrejectRight = (status) => {
    if (status == 4) { // rejected
      return userContent?.rights?.includes(2804);
    }
    return true; // if not rejected then can approve or askformore
  };

  if (loading) {
    return <div className="">
      <div className="fv_application_document-group">
        {[1].map(index => (
          <Card key={index} className=" fv_application_document-card fv_application_document-skeleton-card fv_application_document-shimmer-effect">
            <div className="fv_application_document-skeleton-line"></div>
            <div className="fv_application_document-skeleton-line"></div>
            <div className="fv_application_document-skeleton-line"></div>
          </Card>
        ))}
      </div>
    </div>
  }

  return (
    <Card
      key={userContent?.rights}
      className="fv_application_document-card"
      ref={el => {
        if (detailRefs?.current) {
          detailRefs.current[`${!document?.ubo_id ? "businessDocuments" : document?.ubo_id}-${type}-${docIndex}`] = el;
        }
      }}>
      <div className='d-flex flex-column flex-sm-row align-items-sm-center justify-content-between '>
        <h4 className={`d-flex flex-column flex-md-row align-items-md-center pb-0 pt-${(locked) ? "3" : "0"} my-0`}>
          <div className={`me-2 d-flex align-items-center flex-wrap py-2 mt-sm-0`}>
            <div className={`me-2 `}> {document?.seq ?? 1}.</div>

            <StatusDropDown
              label="Type"
              data={documentTypes}
              value={document?.type}
              asLabel={!userContent?.rights?.includes(2802) || locked || !canChangeType}
              onChange={(v) => { handleTypeChange("type", v) }}
              className={"me-2 mb-1"}
            />

            <DocTypeDropdown
              module={document?.type}
              noLabel
              asLabel={(locked) || !userContent?.rights?.includes(2802)}
              value={document?.docCategory_id}
              onChange={({ value, parent_id }) => { handleTypeChange("docCategory_id", value) }}
              className={"me-2"}
            />

            {(
              !(locked) &&
              hasUnrejectRight(document?.flowStatus) &&
              userContent?.rights?.includes(2802)) ?
              <StatusDropDown
                label="Status"
                data={matchStatusOptions?.filter(v => v?.label != "Locked")}
                value={document?.flowStatus}
                onChange={handleStatusChange}
                className={"me-2"}
              /> : <StatusDisplay value={matchStatusOptions?.find(v => document?.flowStatus == v.value)} />}
          </div>
        </h4>

      </div>


      {(document?.attachment?.urls ?? []).map((url, index) => (
        <InfoPair className="py-0" label={
          <div>
            {getFilenameFromPath(url?.key ?? url)}
            <div>
              Source : {document?.userProvidedDoc != null && document?.userProvidedDoc == false ? "KYC" : "Applicant"}
            </div>
          </div>
        } value={_.toLower(url?.key || '').endsWith('.pdf') ?
          <PDFLabel key={index} src={url.key} />
          :
          <Image key={index} src={url.key} style={{ width: "60px", height: "60px" }} width="60px" height="60px" />} />
      ))}

      {document?.attachment?.urls?.length ? null : <div className='d-flex align-items-center justify-content-center'> <h3>No Documents Found</h3></div>}

      {document.remark ? <InfoPair label="Remark" value={document.remark} /> : null}

      {((document.type === "ProofOfAddress" || document.type === "Business_Proof_Address") && !(document?.userProvidedDoc != null && document?.userProvidedDoc == false)) ?
        <div className='mt-2'><ProofOfAddress formId={document?.form_id} applicationDocId={document?._id} /></div>
        : null}
    </Card>
  );
};

const ApplicationDocumentSkeleton = () => {
  return (
    <div className="fv_application_document_card">
      <h4 className="fv_application_document-skeleton-title fv_application_document-shimmer-effect"></h4>
      <div className="fv_application_document-group">
        {[1, 2, 3].map(index => (
          <Card key={index} className="fv_application_document-card fv_application_document-skeleton-card fv_application_document-shimmer-effect">
            <div className="fv_application_document-skeleton-line"></div>
            <div className="fv_application_document-skeleton-line"></div>
            <div className="fv_application_document-skeleton-line"></div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default ApplicationDocument;

