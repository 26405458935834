import React from "react";
import { Navigate } from "react-router-dom";

// Dashboard
import Dashboard from "../pages/Dashboard/DashboardScreen";
import Profile from "../pages/Profile/Profile";

//

//Pages
// import PagesStarter from "../pages/Utility/pages-starter"
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
// import PagesTimeline from "../pages/Utility/pages-timeline"
// import PagesFaqs from "../pages/Utility/pages-faqs"
// import PagesPricing from "../pages/Utility/pages-pricing"
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

// Authentication related pages
import Loginnew from "../pages/Authentication/Auth";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import Supervisors from "../pages/Supervisor/Supervisor";

//  // Inner Authentication
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";
import Login1 from "../pages/AuthenticationInner/Login";
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
import Register1 from "../pages/AuthenticationInner/Register";

import Roles from "../pages/Roles/Role";


import Auth from "../pages/Authentication/Auth";

import LoginIn from "../pages/Login/LoginIn";
// import TripJourneyGap from "../pages/TripJourneyGap/TripJourneyGap";
import DocCategorySidebar from "../pages/DocCategory/DocCategory";
import Form from "../pages/Form/Applications";
import ApplyIndividualLite from "../pages/Form/forms/ApplyIndividualLite";
import ApplyIndividualLiteContent from "../pages/Form/forms/ApplyIndividualLiteContent";
import PostKYC from "../pages/Form/forms/PostKYC";
import FormQuestions from "../pages/FormQuestions/FormQuestions";
import AccountSearch from "../pages/AccountSearch/AccountSearch";
import AccountSearchDetails from "../pages/AccountSearch/AccountSearchDetails";
import AccountSearchGrid from "../pages/AccountSearch/AccountSearchGrid";
import Reports from "../pages/Reports/Reports";
import RiskCalculation from "../pages/RiskCalculation/RiskCalculation";
import VerificationSteps from "../pages/VerificationSteps/VerificationSteps";
import Disclosures from "../pages/Disclosures/Disclosures";
import DisclosuresDispaly from "../pages/Disclosures/DisclosuresDispaly";
import DocumentType from "../pages/DocumentType/DocumentType";
import EmailLogs from "../pages/EmailLogs/EmailLogs";
import UTMTracker from "../pages/UTMTracker/UTMTracker";
import ReasonCode from "../pages/ReasonCode/ReasonCode";



const userRoutes = [

  { path: "/profile", Component: Profile },


  // =========FV BANK CASS =========
  { path: "/risk-calculation", Component: RiskCalculation },
  { path: "/risk-calculation/edit/:qedit_id", Component: RiskCalculation },
  { path: "/risk-calculation/create/", Component: RiskCalculation },

  { path: "/questions", Component: FormQuestions },
  { path: "/questions/edit/:qedit_id", Component: FormQuestions },
  { path: "/questions/create/", Component: FormQuestions },

  { path: "/disclosures", Component: Disclosures },

  { path: "/applications", Component: Form },
  { path: "/applications/edit/:Bedit_id", Component: Form },
  { path: "/applications/create/", Component: Form },

  { path: "/doc-type", Component: DocumentType },
  { path: "/doc-type/edit/:docEdit_id", Component: DocumentType },
  { path: "/doc-type/create/", Component: DocumentType },

  { path: "/introducer", Component: UTMTracker },
  { path: "/introducer/edit/:trackerEdit_id", Component: UTMTracker },
  { path: "/introducer/create", Component: UTMTracker },

  { path: "/reason-code", Component: ReasonCode },
  { path: "/reason-code/edit/:reasonCodeEdit_id", Component: ReasonCode },
  { path: "/reason-code/create", Component: ReasonCode },


  { path: "/email-logs", Component: EmailLogs },
  { path: "/email-logs/edit/:email_id", Component: EmailLogs },
  { path: "/email-logs/create/", Component: EmailLogs },


  { path: "/accounts", Component: AccountSearch },
  { path: "/accounts/search", Component: AccountSearchGrid },
  { path: "/accounts/detail/:id", Component: AccountSearchDetails },

  { path: "/user", Component: Supervisors },
  { path: "/user/edit/:supervisor_id", Component: Supervisors },
  { path: "/user/create/", Component: Supervisors },

  { path: "/role", Component: Roles },
  { path: "/role/edit/:role_id", Component: Roles },
  { path: "/role/create/", Component: Roles },

  {
    path: "/",
    exact: true,
    Component: () => {
      if (localStorage.getItem("BearerToken")) {
        // return <Navigate to="/applications" />;
        return <Navigate to="/applications" />;
      }
      return <Navigate to="/" />;
    },
  },
];

const authRoutes = [
  // { path : "/" , Component : Login},
  { path: "/logout", Component: Logout },

  {
    path: "/",
    Component: () => {
      if (localStorage.getItem("BearerToken")) {
        return <Navigate to="/applications" />;
      }
      return <Loginnew />;
    },
  },
  {
    path: "/cpanel",
    Component: () => {
      return <Loginnew isCpanelLogin />;
    },
  },

  { path: "/forgot-password", Component: ForgetPwd },
  { path: "/register", Component: Register },
  { path: "/login", Component: LoginIn },
  { path: "/:formType/disclosure", Component: DisclosuresDispaly },

  {
    path: "/sign-up",
    Component: () => {
      if (localStorage.getItem("BearerToken")) {
        return <Navigate to="/applications" />;
      }
      return <Auth />;
    },
  },

  { path: "/pages-maintenance", Component: PagesMaintenance },
  { path: "/pages-comingsoon", Component: PagesComingsoon },
  { path: "/pages-404", Component: Pages404 },
  { path: "/pages-500", Component: Pages500 },

  // Authentication Inner
  { path: "/pages-login", Component: Login1 },
  { path: "/pages-register", Component: Register1 },
  { path: "/page-recoverpw", Component: Recoverpw },
  { path: "/auth-lock-screen", Component: LockScreen },
];

export { authRoutes, userRoutes };
