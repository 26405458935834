import _ from 'lodash';
import moment from 'moment';
import AssignSupervisorFromGrid from './components/AssignSupervisorFromGrid/AssignSupervisorFromGrid';
import StatusProgressSquares from './components/StatusProgressSquares';
import { StatusDisplayInvert, applicationStatuses } from './thirdParty/applicationDocument/ApplicationDocument';
import { FaPlus } from 'react-icons/fa';

const formTypeShortForm = {
  "BUSINESS_PLUS": <b className='d-flex align-items-center'>B <FaPlus size={10} className='ms-1' /></b>,
  "INDIVIDUAL_PLUS": <b className='d-flex align-items-center'>I <FaPlus size={10} className='ms-1' /></b>,
  "INIDIVIDUAL_PRIME": <b>IP</b>,
  "BUSINESS_PRIME": <b>BP</b>,
};

export const GridColumns = (rights) => {
  const columns = [
    {
      title: "Application No.",
      accessor: "applicationNo",
      renderer: (dataObj) => {
        return <div>{dataObj?.applicationNo ?? ""}</div>;
      },
      width: 105,
      show: true,
    },
    {
      title: "Name",
      accessor: "name",
      renderer: (dataObj) => {
        return <div>{dataObj?.customerName ?? ""}</div>;
      },
      show: true,
    },
    {
      title: "Email",
      accessor: "email",
      renderer: (dataObj) => {
        return <div>{dataObj?.email ?? ""}</div>;
      },
      show: true,
      width: 230,
    },
    {
      title: "Type",
      accessor: "applicationType",
      renderer: (dataObj) => {
        return <div>{formTypeShortForm[dataObj?.formType]}</div>;
      },
      show: true,
      width: 50,
    },
    {
      title: "Created At",
      accessor: "createdAt",
      renderer: (dataObj) => {
        return <div>{moment((dataObj?.createdAt) * 1000).format('DD-MM-YYYY, hh:mm a')}</div>;
      },
      show: true,
      width: 130,
    },
    {
      title: "Updated At",
      accessor: "updatedAt",
      renderer: (dataObj) => {
        return <div>{moment((dataObj?.updatedAt ?? dataObj?.createdAt) * 1000).format('DD-MM-YYYY, hh:mm a')}</div>;
      },
      show: false,
      width: 130,
    },
    {
      title: "Introducer Name",
      accessor: "introducer Name",
      renderer: (dataObj) => {
        return <div>{dataObj?.introducer ?? ""}</div>;
      },
      show: true,
      width: 120,
    },
    {
      title: "Status",
      accessor: "status",
      renderer: (dataObj) => {
        return (
          <div>
            <StatusDisplayInvert
              style={{ padding: "0px 6px", minWidth: "120px" }}
              hideIcons
              value={applicationStatuses?.find((v) => dataObj?.status == v.value)}
            />
          </div>
        );
      },
      show: true,
      width: 140,
    },
    {
      title: "Steps",
      accessor: "steps",
      renderer: (dataObj) => {
        const steps = dataObj.steps ?? [];
        return (
          <div>
            <StatusProgressSquares
              data={steps}
              isAccountCreated={[6, 9]?.includes(dataObj?.status)}
              isDeclined={[8]?.includes(dataObj?.status)}
              isQueued={dataObj?.status == 5}
            />
          </div>
        );
      },
      show: true,
      width: 140,
    },
    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 80,
      show: true,
      required: true,
    },
  ];

  // Conditionally append "Assigned To" column if rights include 2300
  if (rights?.includes(2300)) {
    columns.splice(6, 0, {
      title: "Assigned To",
      accessor: "assignedTo",
      show: true,
      renderer: (dataObj) => {
        return <AssignSupervisorFromGrid dataObj={dataObj} />;
      },
      width: 120,
    });
  }

  return {
    version: 1,
    columns: columns,
  };
};
