import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  DataGrid,
  EditLink,
  Layout
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import PagesNoRights from "../Utility/pages-no-rights";
import EditEmailLogs from "./EditEmailLogs";
import { STRUCTURE } from "./EmailLogsConstant";
import { EmailLogsGridColumns } from "./EmailLogsGridColumns";
import EmailLogService from "./EmailLogsService";
import "./style.css";

const EmailLogs = ({ value, insidePane, multiMode, onSelect = () => { } }) => {
  let { email_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showError } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [editID, setEditID] = useState(email_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/email-logs/create" || email_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await EmailLogService.fetch(filterUrl);
      setLoading(false);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };

  useEffect(() => {
    if (userContent?.rights?.includes(1000)) {
      const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE)
      setFilterURL(filterUrl)
      setFilterObject(filterObject)
      fetchData(filterUrl);
      multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
    }
  }, [userContent?.rights])




  useEffect(() => {
    if (email_id) {
      setEditID(email_id)
    } else {
      if (window.location.pathname !== "/email-logs/create") {
        setShowDetailPage(false)
      }
      setEditID(null);
    }
  }, [email_id, window.location.pathname])




  const renderLastCol = useCallback((emailLog) => {
    return (
      <>
        {userContent?.rights?.includes(1000) &&
          <EditLink
            onClick={() => {
              if (!insidePane)
                navigate(`/email-logs/edit/${emailLog?._id}`);
            setEditID(emailLog?._id);
              setShowDetailPage(true);

            }}
          />
        }
     
      </>
    );
  }, [insidePane, multiMode, userContent])


  if (!userContent?.rights?.includes(1000)) {
    return <div key={userContent?.rights} > <PagesNoRights /></div>
  }

  return (
    <React.Fragment key={userContent?.rights}>
      <Layout
        hideTitleBar
        hideAdd
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/email-logs");
          setEditID(null);
        }}
 
        gridLoading={loading}
        title={t("Email Logs")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/email-logs/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={EmailLogService.page}
        rows={EmailLogService.rows}
        total={EmailLogService.total}
        fetch={EmailLogService.fetch}
      >

        <Layout.Table>
          <DataGrid
            data={EmailLogService.records}
            total={EmailLogService.total}
            uiPreference="emailLogs.grid"
            headers={EmailLogsGridColumns}
            onSelectChange={(v) => {
              onSelect(v)
              setSelectedIDs(v)
            }}
            selectedIDs={selectedIDs}
            page={EmailLogService.page}
            rowsPerPage={EmailLogService.rowsPerPage}
            onPaginationChange={EmailLogService.onPaginationChange}
            renderLastCol={renderLastCol}
          />
        </Layout.Table>


        <Layout.DetailPageTitle>
          {t("Email Logs") }
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditEmailLogs
            editId={editID}
            
          />
        </Layout.DetailPageBody>

        
      </Layout>



    </React.Fragment>
  );
};

export default observer(EmailLogs);


